* {
    font-family: "Roboto", sans-serif;
}

body {
    background-color: #000;
    color: #fff;
    &.scroll-hide {
        overflow: hidden;
    }
}

.container {
    max-width: 1660px;
    margin: 0 auto;
    padding: 0 15px;
    @media (max-width: 1500px) {
        max-width: 1220px;
    }
}

.page {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    padding-top: 117px;
    @media (max-width: 1200px) {
        padding-top: 90px;
    }
}

.hidden {
    display: none;
}

.title {
    color: #fff;
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.86px;
    position: relative;
    z-index: 2;
    @media (max-width: 740px) {
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
    }
}

.bottom-shadow {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/bottom-shadow.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 150px;
        bottom: 0;
        left: 0;
        object-fit: cover;
        z-index: 2;
        @media (max-width: 740px) {
            display: none;
        }
    }
}

.top-shadow {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/bottom-shadow.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 150px;
        top: 0;
        transform: rotate(180deg);
        left: 0;
        object-fit: cover;
        @media (max-width: 740px) {
            display: none;
        }
    }
}

.preloader {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 1);
    z-index: 222222;
    transition: background-color 4s ease-in-out;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 150px;
    }
    &.hide {
        display: none;
    }
}

.b24-window-panel > div > .b24-window-scrollable {
    bottom: inherit !important;
}

.b24-form-click-btn {
    margin-top: 50px !important;
}
