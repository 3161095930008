.storries {
    padding: 60px 0;
}
.container {
}
.title {
}
.storries__wrapper {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
}
.storries-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.storries-item__img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}
.storries-item__title {
    color: #ffffff;
    font-size: 28px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.43px;
    margin-top: 30px;
    height: 105px;
}
.storries-item__descr {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25px;
    margin-top: 20px;
}

@media (max-width: 991px) {
    .storries__wrapper {
        grid-template-columns: 1fr;
        gap: 40px;
    }
    .storries-item__title {
        height: auto;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        margin-top: 20px;
    }

    .storries-item__img {
        width: 150px;
        height: 150px;
    }
}
