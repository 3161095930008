.service {
    padding: 50px 0;
    position: relative;
    overflow: hidden;
}
.container {
}
.title {
}
.service__wrapper {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.service-item__title {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.43px;
}
.service-item__list {
    margin-top: 30px;
    li {
        display: flex;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.29px;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        div {
            width: 35px;
            min-width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }
    }
}

.service__img {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    height: 90%;
}

@media (max-width: 740px) {
    .service__wrapper {
        grid-template-columns: 1fr;
    }

    .service__img {
        opacity: 0.6;
    }
}
