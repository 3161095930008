.footer {
    padding-top: 75px;
    .container {
        position: relative;
    }
}

.footer__wrapper {
    display: flex;
    margin-top: 40px;
}
.footer-col {
    max-width: 436px;
    width: fit-content;
    margin-right: 60px;
    &:last-child {
        margin-right: 0;
    }
    a {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        display: block;
        transition: all 0.3s ease-in-out;
        &:hover {
            opacity: 0.6;
        }
    }
}
.footer__logo {
    max-width: 130px;
    display: block;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}
.descr {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.25px;
}
.post {
    margin-top: 55px;
}
.soc-wrap {
    margin-top: 40px;
    display: flex;
    a {
        margin-right: 30px;
        width: 40px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
}
.in {
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
}
.footer__bottom {
    padding: 20px;
    text-align: center;
    color: #ffffff;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.25px;
}
.footer__img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

@media (max-width: 580px) {
    .footer .container {
        flex-direction: column;
    }

    .footer__img {
        opacity: 0.3;
    }

    .post {
        margin-top: 30px;
    }

    .footer {
        padding-top: 40px;
    }
}
