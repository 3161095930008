.contact {
    padding: 40px 0;
    background-color: #3e3e3e;
}

.form {
    margin-top: 40px;
    > div {
        display: flex;
        justify-content: flex-end;
        color: #ffffff;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.43px;
        margin-bottom: 20px;
        label {
            max-width: 990px;
            width: 100%;
            margin-left: 20px;
            input {
                height: 40px;
                border: 1px solid #ffffff;
                background-color: #000000;
                width: 100%;
                border-radius: 8px;
                padding: 0 20px;
                color: #ffffff;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0.43px;
            }
            textarea {
                height: 140px;
                border: 1px solid #ffffff;
                background-color: #000000;
                width: 100%;
                border-radius: 8px;
                padding: 20px;
                color: #ffffff;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0.43px;
                resize: none;
            }
        }
    }
}

.btn {
    width: fit-content;
    padding: 8px 20px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.43px;
    background-color: #000000;
    transition: all 0.3s ease-in-out;
    border: 1px solid #ffffff;
    &:hover {
        background-color: #fff;
        color: #000000;
    }
}

.form__btn {
    margin-left: auto;
}

@media (max-width: 1200px) {
    .form > div {
        flex-direction: column;
        line-height: 35px;
        margin-bottom: 10px;
    }

    .form > div label {
        margin-left: 0;
        max-width: 100%;
    }
}
