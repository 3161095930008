.section-five {
    overflow: hidden;
}

.section-five__wrapper {
    display: flex;
}
.section-five__img {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 50%;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        margin-top: auto;
    }
}
.section-five__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 650px;
    margin-left: auto;
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.36px;
    padding: 50px 0;
    position: relative;
    z-index: 2;
    img {
        max-width: 150px;
        max-height: 150px;
        object-fit: cover;
        margin-bottom: 50px;
    }
}

@media (max-width: 991px) {
    .section-five__right {
        font-size: 16px;
        line-height: 28px;
        margin-left: 39px;
    }

    .section-five__right img {
        max-width: 100px;
        max-height: 100px;
        margin-bottom: 30px;
    }

    .section-five__img {
        width: 70%;
    }
}

@media (max-width: 740px) {
    .section-five__wrapper {
        flex-direction: column-reverse;
        margin-bottom: 40px;
    }

    .section-five__img {
        width: auto;
        margin: 0 auto;
    }

    .section-five__right {
        margin-left: 0;
        padding-bottom: 0;
    }
}
