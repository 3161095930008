.ideas {
    padding: 35px 0;
}
.container {
}
.ideas__title {
    color: #e6505f;
    font-size: 70px;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 1.61px;
    max-width: 555px;
}
.ideas__wrapper {
    margin-top: 40px;
}
.ideas-item {
    display: flex;
    margin-bottom: 40px;
    &:nth-child(even) {
        flex-direction: row-reverse;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.ideas-item-info {
    min-height: 450px;
    width: 50%;
    padding: 30px 30px 30px;
    display: flex;
    flex-direction: column;
}
.ideas-item__title {
    color: #ffffff;
    font-size: 26px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.43px;
}
.ideas-item__list {
    margin-top: 30px;
    margin-bottom: 40px;
    li {
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.29px;
        list-style: none;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.ideas-item__bottom {
    margin-top: auto;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    width: calc(100% + 60px);
    transform: translateX(-30px);
    padding: 10px 30px;
    min-height: 85px;
    div {
        color: #000000;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.29px;
        display: flex;
        align-items: flex-end;
        img {
            max-width: 30px;
            max-height: 100%;
            object-fit: cover;
            margin-right: 12px;
        }
    }
}
.ideas-item__img {
    width: 50%;
    position: relative;

    img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 740px) {
    .ideas {
        padding-top: 50px;
    }
    .ideas__title {
        font-weight: 700;
        line-height: 54px;
        font-size: 52px;
    }

    .ideas-item {
        flex-direction: column;
    }

    .ideas-item:nth-child(even) {
        flex-direction: column;
    }

    .ideas-item__img {
        height: 50vh;
        margin: 20px auto 0 auto;
        width: 100%;
    }

    .ideas-item-info {
        width: 100%;
        padding: 20px;
        min-height: fit-content;
    }

    .ideas-item__title {
        font-size: 22px;
        font-weight: 700;
        line-height: 27px;
    }

    .ideas-item__list {
        margin: 20px 0;
        li {
            margin-bottom: 10px;
        }
    }

    .ideas-item__bottom {
        padding: 10px 15px;
        min-height: fit-content;
    }

    .ideas-item__bottom div img {
        max-width: 16px;
    }
    .ideas-item__bottom div {
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
    }
}
