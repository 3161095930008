*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

a {
    text-decoration: none;
}

body {
    box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
    display: block;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

ul li {
    list-style: none;
}

dl,
ol,
ul {
    margin: 0;
    padding: 0;
}

label {
    margin: 0;
}

p {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    font-size: inherit;
    margin: 0;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
