.section-four {
    background-color: #3e3e3e;
}
.container {
}
.section-four__wrapper {
    display: flex;
    justify-content: space-between;
}
.section-four__left {
    padding: 60px 0;
}
.section-four__title {
    color: #99c07d;
    font-size: 90px;
    font-weight: 700;
    line-height: 100px;
    letter-spacing: 1.61px;
}
.section-four__descr {
    color: #ffffff;
    font-size: 28px;
    line-height: 37px;
    font-weight: 400;
    letter-spacing: 0.43px;
    margin-top: 45px;
    b {
        font-weight: 700;
    }
}
.section-four__bottom {
    margin-top: 100px;
    color: #99c07d;
    font-family: "Zing Script Rust SB Demo - Base";
    font-size: 42px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.72px;
    padding-top: 46px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 150px;
        height: 5px;
        background-color: #99c07d;
        left: 0;
        top: 0;
    }
}
.section-four__gif {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    padding: 50px 0;
    padding-bottom: 75px;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
    .gif-text {
        background-color: #3e3e3e;
        display: flex;
        position: absolute;
        justify-content: space-between;
        width: calc(100% + 30px);
        left: 50%;
        transform: translateX(-50%);
        font-size: 27px;
        height: 0;
        padding: 0 20px;
        text-align: center;
        span {
            display: block;
            width: 33.33%;
        }
    }
}

@media (max-width: 1000px) {
    .section-four__gif .gif-text {
        font-size: 20px;
    }
}

@media (max-width: 740px) {
    .section-four__wrapper {
        flex-direction: column;
    }

    .section-four__gif {
        max-height: 0%;
    }

    .section-four__left {
        padding-top: 40px;
    }

    .section-four__title {
        font-size: 60px;
        font-weight: 700;
        line-height: 60px;
    }

    .section-four__descr {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        margin-top: 30px;
    }
    .section-four__bottom {
        font-size: 36px;
        font-weight: 400;
        line-height: 35px;
        padding-top: 20px;
        margin-top: 30px;
    }
    .section-four__gif .gif-text {
        font-size: 16px;
    }
}
