.section-two {
    padding: 40px 0;
    padding-bottom: 30px;
    .title {
        color: #a0c882;
    }
}
.container {
}

.section-two__title {
    color: #ffffff;
    font-size: 80px;
    font-weight: 700;
    line-height: 90px;
    letter-spacing: 1.61px;
    margin-top: 30px;
}
.section-two__wrapper {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.section-two-item {
    position: relative;
    min-height: 450px;
}
.section-two-item__title {
    color: #a0dcf0;
    font-family: "Zing Script Rust SB Demo - Base";
    font-size: 28px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.43px;
    max-width: 185px;
}
.section-two-item__list {
    margin-top: 45px;
    li {
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.25px;
        margin-bottom: 20px;
        display: flex;
        &::before {
            content: "+";
            color: currentColor;
            font-size: 21px;
            margin-right: 5px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.section-two-item__img {
    position: absolute;
    right: -35px;
    bottom: 0;
    pointer-events: none;
    z-index: -1;
    max-height: 500px;
}

@media (max-width: 991px) {
    .section-two-item__img {
        max-height: 380px;
    }
}

@media (max-width: 740px) {
    .section-two__wrapper {
        grid-template-columns: 1fr;
    }

    .section-two__title {
        font-size: 50px;
        line-height: 50px;
    }
}
