.modal-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.69);
    box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.24);
    z-index: 20000000;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.modal {
    @include scroll-hide;
    overflow-y: auto;
    height: fit-content;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 95vh;
    min-width: 440px;
    max-width: 440px;
}

.modal__body {
    background-color: #3e3e3e;
    box-shadow: 4px 4px 15px rgba(67, 0, 119, 0.18);
    border-radius: 10px;
    padding: 30px;
    width: 100%;
    position: relative;
    text-align: center;
    a {
        display: block;
        margin-bottom: 20px;
        font-size: 22px;
        color: #fff;
        transition: letter-spacing 0.2s ease-in-out;
        &:hover {
            letter-spacing: 1px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.modal__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
    filter: invert(1);
    &::before {
        content: "";
        position: absolute;
        background-image: url("../img/close.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 34px;
        height: 34px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.modal__text {
    margin-bottom: 20px;
}
