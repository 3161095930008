.promo {
    position: relative;
    .swiper {
    }
    .promo-swiper {
    }
    .swiper-wrapper {
    }
    .swiper-slide {
        min-height: 100%;
        height: 75vh;
        background-color: #000;
    }

    .swiper-pagination {
        max-width: 480px;
        right: 15px;
        left: inherit;
        bottom: 25px;
    }

    .swiper-pagination-bullet {
        width: 18px;
        height: 18px;
        background-color: #3e3e3e;
        opacity: 1;
        margin: 0 15px !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666;
    }

    .container {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.promo-item__img {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}

.promo-item {
    padding: 55px 0 85px 0;
    z-index: 2;
}

.promo-item__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    z-index: -1;
}

.promo-item__ofer {
    max-width: 480px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
}

.promo-item__img {
}
.promo-item__title {
    color: #fffefe;
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.65px;
    margin-top: 50px;
}
.promo-item__subtitle {
    color: #fffefe;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.25px;
    margin-top: 20px;
}

@media (max-width: 1200px) {
    .promo .swiper-slide {
        height: calc(100vh - 90px);
    }

    .promo-item__ofer {
        margin: 0 auto;
    }

    .promo .swiper-pagination {
        max-width: 100%;
    }

    .promo-item__bg {
        opacity: 0.8;
    }
}

@media (max-width: 480px) {
    .mob-transform {
        transform: translate(89px, 0px) rotate(-12deg);
    }
}
