.products {
    padding: 100px 0 65px;
    overflow: hidden;
}

.container {
}
.title {
}
.products__wrapper {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    gap: 20px;
}

.products-item {
    display: flex;
}
.products-item__img {
    margin-right: -25px;
    z-index: 2;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}
.products-item__info {
    width: 330px;
    min-width: 330px;
    padding: 40px 22px 40px 50px;
    background-color: #3e3e3e;
    transform: translateY(-15px);
}
.products-item__title {
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.43px;
    height: 53px;
    margin-bottom: 20px;
}
.products-item__descr {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.29px;
}

@media (max-width: 991px) {
    .products__wrapper {
        grid-template-columns: 1fr;
    }

    .products-item__img {
        max-height: 300px;
    }

    .products-item__info {
        width: calc(100% - 230px);
        height: 320px;
    }

    .products {
        padding: 40px 0;
    }
}

@media (max-width: 580px) {
    .products-item__img {
        max-height: 240px;
        width: 170px;
    }

    .products-item__info {
        width: calc(100% - 145px);
        padding: 20px 20px 20px 40px;
        height: fit-content;
        min-height: 340px;
        min-width: inherit;
    }

    .products-item__title {
        font-size: 18px;
        line-height: 24px;
        height: auto;
    }

    .products-item__descr {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
    }

    .products-item--last .products-item__img {
        margin-top: 50px;
    }
}
