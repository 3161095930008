:root {
  --color-black: #000;
  --color-white: #fff;
}

@font-face {
  font-family: "Zing Script Rust SB Demo - Base";
  src: url("../fonts/ZingScriptRustSBDemo-Base.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a {
  text-decoration: none;
}

body {
  box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

ul li {
  list-style: none;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

input:not([type=checkbox]):not([type=radio]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

* {
  font-family: "Roboto", sans-serif;
}

body {
  background-color: #000;
  color: #fff;
}
body.scroll-hide {
  overflow: hidden;
}

.container {
  max-width: 1660px;
  margin: 0 auto;
  padding: 0 15px;
}
@media (max-width: 1500px) {
  .container {
    max-width: 1220px;
  }
}

.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 117px;
}
@media (max-width: 1200px) {
  .page {
    padding-top: 90px;
  }
}

.hidden {
  display: none;
}

.title {
  color: #fff;
  font-size: 44px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.86px;
  position: relative;
  z-index: 2;
}
@media (max-width: 740px) {
  .title {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
  }
}

.bottom-shadow {
  position: relative;
  z-index: 1;
}
.bottom-shadow::before {
  content: "";
  position: absolute;
  background-image: url("../img/bottom-shadow.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 150px;
  bottom: 0;
  left: 0;
  object-fit: cover;
  z-index: 2;
}
@media (max-width: 740px) {
  .bottom-shadow::before {
    display: none;
  }
}

.top-shadow {
  position: relative;
  z-index: 1;
}
.top-shadow::before {
  content: "";
  position: absolute;
  background-image: url("../img/bottom-shadow.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 150px;
  top: 0;
  transform: rotate(180deg);
  left: 0;
  object-fit: cover;
}
@media (max-width: 740px) {
  .top-shadow::before {
    display: none;
  }
}

.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: black;
  z-index: 222222;
  transition: background-color 4s ease-in-out;
}
.preloader img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
}
.preloader.hide {
  display: none;
}

.b24-window-panel > div > .b24-window-scrollable {
  bottom: inherit !important;
}

.b24-form-click-btn {
  margin-top: 50px !important;
}

.header {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  z-index: 200;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 1200px) {
  .header.fixed .logo {
    width: 150px;
  }
  .header.fixed .lang {
    opacity: 0;
  }
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  transition: all 0.3s ease-in-out;
  width: 150px;
}
.logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.nav__list {
  display: flex;
}
.nav__list:hover .nav__item {
  opacity: 0.6;
}

.nav__item {
  margin-left: 25px;
  transition: all 0.3s ease-in-out;
}
.nav__item:hover {
  opacity: 1 !important;
}
.nav__item:hover .nav__link::before {
  transform: translateY(0);
  opacity: 1;
}

.nav__link {
  color: #fffefe;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.25px;
  position: relative;
}
.nav__link::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  height: 1px;
  width: 100%;
  background-color: currentColor;
  bottom: -2px;
  transform: translateY(10px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.lang {
  position: absolute;
  right: 15px;
  top: -4px;
  color: #fffefe;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.lang a {
  color: #fffefe;
  transition: all 0.3s ease-in-out;
}
.lang a:hover {
  opacity: 0.6;
}

@media (max-width: 1200px) {
  .nav {
    position: fixed;
    width: calc(100% - 60px);
    height: 100%;
    left: 0;
    top: 0;
    background-color: #000;
    z-index: 1222222;
    padding: 100px 20px;
    transform: translateX(-110%);
    transition: all 0.3s ease-in-out;
  }
  .nav.active {
    transform: translateX(0);
  }

  .nav__list {
    flex-direction: column;
  }

  .header .burger {
    display: block;
  }

  .logo {
    width: 100px;
    position: relative;
    z-index: 222222222;
  }

  .preloader img {
    width: 100px;
  }

  .nav__item {
    margin-bottom: 20px;
    margin-left: 0;
  }

  .lang {
    position: static;
    margin-top: 50px;
  }
}
.promo {
  position: relative;
}
.promo .swiper-slide {
  min-height: 100%;
  height: 75vh;
  background-color: #000;
}
.promo .swiper-pagination {
  max-width: 480px;
  right: 15px;
  left: inherit;
  bottom: 25px;
}
.promo .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  background-color: #3e3e3e;
  opacity: 1;
  margin: 0 15px !important;
}
.promo .swiper-pagination-bullet-active {
  background-color: #666666;
}
.promo .container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.promo-item__img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.promo-item__img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.promo-item {
  padding: 55px 0 85px 0;
  z-index: 2;
}

.promo-item__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -1;
}

.promo-item__ofer {
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: auto;
}

.promo-item__title {
  color: #fffefe;
  font-size: 44px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.65px;
  margin-top: 50px;
}

.promo-item__subtitle {
  color: #fffefe;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.25px;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .promo .swiper-slide {
    height: calc(100vh - 90px);
  }

  .promo-item__ofer {
    margin: 0 auto;
  }

  .promo .swiper-pagination {
    max-width: 100%;
  }

  .promo-item__bg {
    opacity: 0.8;
  }
}
@media (max-width: 480px) {
  .mob-transform {
    transform: translate(89px, 0px) rotate(-12deg);
  }
}
.section-two {
  padding: 40px 0;
  padding-bottom: 30px;
}
.section-two .title {
  color: #a0c882;
}

.section-two__title {
  color: #ffffff;
  font-size: 80px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: 1.61px;
  margin-top: 30px;
}

.section-two__wrapper {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.section-two-item {
  position: relative;
  min-height: 450px;
}

.section-two-item__title {
  color: #a0dcf0;
  font-family: "Zing Script Rust SB Demo - Base";
  font-size: 28px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.43px;
  max-width: 185px;
}

.section-two-item__list {
  margin-top: 45px;
}
.section-two-item__list li {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.25px;
  margin-bottom: 20px;
  display: flex;
}
.section-two-item__list li::before {
  content: "+";
  color: currentColor;
  font-size: 21px;
  margin-right: 5px;
}
.section-two-item__list li:last-child {
  margin-bottom: 0;
}

.section-two-item__img {
  position: absolute;
  right: -35px;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
  max-height: 500px;
}

@media (max-width: 991px) {
  .section-two-item__img {
    max-height: 380px;
  }
}
@media (max-width: 740px) {
  .section-two__wrapper {
    grid-template-columns: 1fr;
  }

  .section-two__title {
    font-size: 50px;
    line-height: 50px;
  }
}
.section-three {
  padding: 65px 0 30px 0;
}
.section-three .title {
  color: #e6505f;
}

.section-three__wrapper {
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.section-three__wrapper > div {
  overflow: hidden;
  /* &:nth-child(3) {
      --animate-delay: 2s;
  } */
}
.section-three__wrapper > div:last-child .section-three-item__text img {
  opacity: 0;
}
.section-three-item {
  display: flex;
}

.section-three-item__img img {
  max-width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.section-three-item__text {
  max-width: 160px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 24px;
  font-size: 16px;
  padding-top: 78px;
  margin-left: 15px;
}
.section-three-item__text img {
  margin-bottom: 37px;
  max-width: 100%;
  max-height: 30px;
  object-fit: cover;
}
.section-three-item__text b {
  font-weight: 700;
}

@media (max-width: 991px) {
  .section-three__wrapper {
    grid-template-columns: 1fr;
  }

  .section-three-item {
    justify-content: center;
    margin-bottom: 20px;
  }
  .section-three-item:last-child {
    margin-bottom: 0;
  }

  .section-three-item__text {
    padding-top: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .section-three-item__text img {
    width: fit-content;
    transform: rotate(90deg);
    margin-top: 25px;
  }

  .section-three-item__img {
    margin-right: 20px;
  }
  .section-three-item__img img {
    max-height: 300px;
  }
}
.section-four {
  background-color: #3e3e3e;
}

.section-four__wrapper {
  display: flex;
  justify-content: space-between;
}

.section-four__left {
  padding: 60px 0;
}

.section-four__title {
  color: #99c07d;
  font-size: 90px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 1.61px;
}

.section-four__descr {
  color: #ffffff;
  font-size: 28px;
  line-height: 37px;
  font-weight: 400;
  letter-spacing: 0.43px;
  margin-top: 45px;
}
.section-four__descr b {
  font-weight: 700;
}

.section-four__bottom {
  margin-top: 100px;
  color: #99c07d;
  font-family: "Zing Script Rust SB Demo - Base";
  font-size: 42px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.72px;
  padding-top: 46px;
  position: relative;
}
.section-four__bottom::before {
  position: absolute;
  content: "";
  width: 150px;
  height: 5px;
  background-color: #99c07d;
  left: 0;
  top: 0;
}

.section-four__gif {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding: 50px 0;
  padding-bottom: 75px;
}
.section-four__gif img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.section-four__gif .gif-text {
  background-color: #3e3e3e;
  display: flex;
  position: absolute;
  justify-content: space-between;
  width: calc(100% + 30px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 27px;
  height: 0;
  padding: 0 20px;
  text-align: center;
}
.section-four__gif .gif-text span {
  display: block;
  width: 33.33%;
}

@media (max-width: 1000px) {
  .section-four__gif .gif-text {
    font-size: 20px;
  }
}
@media (max-width: 740px) {
  .section-four__wrapper {
    flex-direction: column;
  }

  .section-four__gif {
    max-height: 0%;
  }

  .section-four__left {
    padding-top: 40px;
  }

  .section-four__title {
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
  }

  .section-four__descr {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    margin-top: 30px;
  }

  .section-four__bottom {
    font-size: 36px;
    font-weight: 400;
    line-height: 35px;
    padding-top: 20px;
    margin-top: 30px;
  }

  .section-four__gif .gif-text {
    font-size: 16px;
  }
}
.section-five {
  overflow: hidden;
}

.section-five__wrapper {
  display: flex;
}

.section-five__img {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
}
.section-five__img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  margin-top: auto;
}

.section-five__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 650px;
  margin-left: auto;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.36px;
  padding: 50px 0;
  position: relative;
  z-index: 2;
}
.section-five__right img {
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .section-five__right {
    font-size: 16px;
    line-height: 28px;
    margin-left: 39px;
  }

  .section-five__right img {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 30px;
  }

  .section-five__img {
    width: 70%;
  }
}
@media (max-width: 740px) {
  .section-five__wrapper {
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }

  .section-five__img {
    width: auto;
    margin: 0 auto;
  }

  .section-five__right {
    margin-left: 0;
    padding-bottom: 0;
  }
}
.service {
  padding: 50px 0;
  position: relative;
  overflow: hidden;
}

.service__wrapper {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.service-item__title {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.43px;
}

.service-item__list {
  margin-top: 30px;
}
.service-item__list li {
  display: flex;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.29px;
  margin-bottom: 20px;
}
.service-item__list li:last-child {
  margin-bottom: 0;
}
.service-item__list li div {
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.service-item__list li div img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.service__img {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  height: 90%;
}

@media (max-width: 740px) {
  .service__wrapper {
    grid-template-columns: 1fr;
  }

  .service__img {
    opacity: 0.6;
  }
}
.products {
  padding: 100px 0 65px;
  overflow: hidden;
}

.products__wrapper {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  gap: 20px;
}

.products-item {
  display: flex;
}

.products-item__img {
  margin-right: -25px;
  z-index: 2;
}
.products-item__img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.products-item__info {
  width: 330px;
  min-width: 330px;
  padding: 40px 22px 40px 50px;
  background-color: #3e3e3e;
  transform: translateY(-15px);
}

.products-item__title {
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.43px;
  height: 53px;
  margin-bottom: 20px;
}

.products-item__descr {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.29px;
}

@media (max-width: 991px) {
  .products__wrapper {
    grid-template-columns: 1fr;
  }

  .products-item__img {
    max-height: 300px;
  }

  .products-item__info {
    width: calc(100% - 230px);
    height: 320px;
  }

  .products {
    padding: 40px 0;
  }
}
@media (max-width: 580px) {
  .products-item__img {
    max-height: 240px;
    width: 170px;
  }

  .products-item__info {
    width: calc(100% - 145px);
    padding: 20px 20px 20px 40px;
    height: fit-content;
    min-height: 340px;
    min-width: inherit;
  }

  .products-item__title {
    font-size: 18px;
    line-height: 24px;
    height: auto;
  }

  .products-item__descr {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }

  .products-item--last .products-item__img {
    margin-top: 50px;
  }
}
.advantages {
  padding: 80px 0 60px;
  background-color: #3e3e3e;
}

.advantages__wrapper {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 250px));
  gap: 20px;
  justify-content: space-between;
}
.advantages__wrapper > div {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.advantages__wrapper > div::before {
  content: "";
  background-image: url("../img/check.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 27px;
  height: 27px;
  margin-bottom: 13px;
}
.advantages__wrapper > div h3 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.43px;
  min-height: 80px;
  margin-bottom: 40px;
}
.advantages__wrapper > div div {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.29px;
}

@media (max-width: 900px) {
  .advantages {
    padding: 40px 0;
  }

  .advantages .advantages__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .advantages__wrapper > div h3 {
    min-height: inherit;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
}
.ideas {
  padding: 35px 0;
}

.ideas__title {
  color: #e6505f;
  font-size: 70px;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: 1.61px;
  max-width: 555px;
}

.ideas__wrapper {
  margin-top: 40px;
}

.ideas-item {
  display: flex;
  margin-bottom: 40px;
}
.ideas-item:nth-child(even) {
  flex-direction: row-reverse;
}
.ideas-item:last-child {
  margin-bottom: 0;
}

.ideas-item-info {
  min-height: 450px;
  width: 50%;
  padding: 30px 30px 30px;
  display: flex;
  flex-direction: column;
}

.ideas-item__title {
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0.43px;
}

.ideas-item__list {
  margin-top: 30px;
  margin-bottom: 40px;
}
.ideas-item__list li {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.29px;
  list-style: none;
  margin-bottom: 20px;
}
.ideas-item__list li:last-child {
  margin-bottom: 0;
}

.ideas-item__bottom {
  margin-top: auto;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  width: calc(100% + 60px);
  transform: translateX(-30px);
  padding: 10px 30px;
  min-height: 85px;
}
.ideas-item__bottom div {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.29px;
  display: flex;
  align-items: flex-end;
}
.ideas-item__bottom div img {
  max-width: 30px;
  max-height: 100%;
  object-fit: cover;
  margin-right: 12px;
}

.ideas-item__img {
  width: 50%;
  position: relative;
}
.ideas-item__img img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 740px) {
  .ideas {
    padding-top: 50px;
  }

  .ideas__title {
    font-weight: 700;
    line-height: 54px;
    font-size: 52px;
  }

  .ideas-item {
    flex-direction: column;
  }

  .ideas-item:nth-child(even) {
    flex-direction: column;
  }

  .ideas-item__img {
    height: 50vh;
    margin: 20px auto 0 auto;
    width: 100%;
  }

  .ideas-item-info {
    width: 100%;
    padding: 20px;
    min-height: fit-content;
  }

  .ideas-item__title {
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
  }

  .ideas-item__list {
    margin: 20px 0;
  }
  .ideas-item__list li {
    margin-bottom: 10px;
  }

  .ideas-item__bottom {
    padding: 10px 15px;
    min-height: fit-content;
  }

  .ideas-item__bottom div img {
    max-width: 16px;
  }

  .ideas-item__bottom div {
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
  }
}
.lovers {
  padding: 85px 0;
  height: 670px;
  background-color: #3e3e3e;
}

.lovers__wrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  min-height: 100%;
  z-index: 1;
}

.lovers__img {
  position: absolute;
  left: 120px;
  top: 0;
  max-width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: cover;
  z-index: -1;
}

.lovers__title {
  color: #ffffff;
  font-size: 70px;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: 1.61px;
  max-width: 700px;
}

.lovers__right {
  max-width: 700px;
  margin-left: 40px;
}
.lovers__right h3 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.43px;
}
.lovers__right p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  margin-top: 75px;
}

.lovers-icons {
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
.lovers-icons > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.25px;
}
.lovers-icons > div div {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.lovers-icons > div div img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

@media (max-width: 740px) {
  .lovers {
    padding: 40px 0;
    height: fit-content;
  }

  .lovers__wrapper {
    flex-direction: column;
    min-height: fit-content;
  }

  .lovers__img {
    opacity: 0.2;
  }

  .lovers__right {
    margin-left: 0;
    max-width: 100%;
    margin-top: 30px;
  }

  .lovers__title {
    font-size: 52px;
    font-weight: 700;
    line-height: 66px;
  }

  .lovers__right p {
    margin-top: 40px;
  }
}
@media (max-width: 480px) {
  .lovers-icons > div div {
    width: 50px;
    height: 50px;
  }

  .lovers-icons > div {
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
  }
}
.contact {
  padding: 40px 0;
  background-color: #3e3e3e;
}

.form {
  margin-top: 40px;
}
.form > div {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.43px;
  margin-bottom: 20px;
}
.form > div label {
  max-width: 990px;
  width: 100%;
  margin-left: 20px;
}
.form > div label input {
  height: 40px;
  border: 1px solid #ffffff;
  background-color: #000000;
  width: 100%;
  border-radius: 8px;
  padding: 0 20px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.43px;
}
.form > div label textarea {
  height: 140px;
  border: 1px solid #ffffff;
  background-color: #000000;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.43px;
  resize: none;
}

.btn {
  width: fit-content;
  padding: 8px 20px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.43px;
  background-color: #000000;
  transition: all 0.3s ease-in-out;
  border: 1px solid #ffffff;
}
.btn:hover {
  background-color: #fff;
  color: #000000;
}

.form__btn {
  margin-left: auto;
}

@media (max-width: 1200px) {
  .form > div {
    flex-direction: column;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .form > div label {
    margin-left: 0;
    max-width: 100%;
  }
}
.modal-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.69);
  box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.24);
  z-index: 20000000;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-wrap.active {
  opacity: 1;
  visibility: visible;
}

.modal {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
  height: fit-content;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 95vh;
  min-width: 440px;
  max-width: 440px;
}
.modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.modal__body {
  background-color: #3e3e3e;
  box-shadow: 4px 4px 15px rgba(67, 0, 119, 0.18);
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  position: relative;
  text-align: center;
}
.modal__body a {
  display: block;
  margin-bottom: 20px;
  font-size: 22px;
  color: #fff;
  transition: letter-spacing 0.2s ease-in-out;
}
.modal__body a:hover {
  letter-spacing: 1px;
}
.modal__body a:last-child {
  margin-bottom: 0;
}

.modal__close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
  filter: invert(1);
}
.modal__close::before {
  content: "";
  position: absolute;
  background-image: url("../img/close.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 34px;
  height: 34px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal__text {
  margin-bottom: 20px;
}

.footer {
  padding-top: 75px;
}
.footer .container {
  position: relative;
}

.footer__wrapper {
  display: flex;
  margin-top: 40px;
}

.footer-col {
  max-width: 436px;
  width: fit-content;
  margin-right: 60px;
}
.footer-col:last-child {
  margin-right: 0;
}
.footer-col a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: block;
  transition: all 0.3s ease-in-out;
}
.footer-col a:hover {
  opacity: 0.6;
}

.footer__logo {
  max-width: 130px;
  display: block;
}
.footer__logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.descr {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.post {
  margin-top: 55px;
}

.soc-wrap {
  margin-top: 40px;
  display: flex;
}
.soc-wrap a {
  margin-right: 30px;
  width: 40px;
}
.soc-wrap a img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.in {
  margin-bottom: 15px;
}
.in:last-child {
  margin-bottom: 0;
}

.footer__bottom {
  padding: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25px;
}

.footer__img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media (max-width: 580px) {
  .footer .container {
    flex-direction: column;
  }

  .footer__img {
    opacity: 0.3;
  }

  .post {
    margin-top: 30px;
  }

  .footer {
    padding-top: 40px;
  }
}
.burger {
  height: 16px;
  width: 30px;
  justify-content: center;
  align-items: center;
  z-index: 350;
  cursor: pointer;
  transition: all 0.3s;
  display: none;
  position: relative;
}
.burger span {
  position: absolute;
  background: #fff;
  height: 3px;
  width: 30px;
  display: block;
  transition: all 0.3s;
  border-radius: 5px;
}
.burger span:nth-child(1) {
  top: 16px;
}
.burger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.burger span:nth-child(3) {
  bottom: 16px;
}
.burger.active span:nth-child(1) {
  top: 6px;
  transform: rotate(45deg);
}
.burger.active span:nth-child(2) {
  opacity: 0;
}
.burger.active span:nth-child(3) {
  bottom: 7px;
  transform: rotate(-45deg);
}

.storries {
  padding: 60px 0;
}

.storries__wrapper {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
}

.storries-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.storries-item__img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.storries-item__title {
  color: #ffffff;
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.43px;
  margin-top: 30px;
  height: 105px;
}

.storries-item__descr {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .storries__wrapper {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .storries-item__title {
    height: auto;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-top: 20px;
  }

  .storries-item__img {
    width: 150px;
    height: 150px;
  }
}