.advantages {
    padding: 80px 0 60px;
    background-color: #3e3e3e;
}
.container {
}
.title {
}
.advantages__wrapper {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 250px));
    gap: 20px;
    justify-content: space-between;
    > div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        &::before {
            content: "";
            background-image: url("../img/check.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: 27px;
            height: 27px;
            margin-bottom: 13px;
        }
        h3 {
            color: #ffffff;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: 0.43px;
            min-height: 80px;
            margin-bottom: 40px;
        }
        div {
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.29px;
        }
    }
}

@media (max-width: 900px) {
    .advantages {
        padding: 40px 0;
    }
    .advantages .advantages__wrapper {
        grid-template-columns: repeat(2, 1fr);
    }

    .advantages__wrapper > div h3 {
        min-height: inherit;
        margin-bottom: 25px;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
    }
}
