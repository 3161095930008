.section-three {
    padding: 65px 0 30px 0;
    .title {
        color: #e6505f;
    }
}
.container {
}
.title {
}
.section-three__wrapper {
    margin-top: 35px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    > div {
        overflow: hidden;
        &:last-child {
            .section-three-item__text img {
                opacity: 0;
            }
        }
        &:nth-child(2) {
            .section-three-item {
            }
        }
        /* &:nth-child(3) {
            --animate-delay: 2s;
        } */
    }
}
.section-three-item {
    display: flex;
}
.section-three-item__img {
    img {
        max-width: 100%;
        max-height: 400px;
        object-fit: cover;
    }
}
.section-three-item__text {
    max-width: 160px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 24px;
    font-size: 16px;
    padding-top: 78px;
    margin-left: 15px;
    img {
        margin-bottom: 37px;
        max-width: 100%;
        max-height: 30px;
        object-fit: cover;
    }
    b {
        font-weight: 700;
    }
}

@media (max-width: 991px) {
    .section-three__wrapper {
        grid-template-columns: 1fr;
    }

    .section-three-item {
        justify-content: center;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .section-three-item__text {
        padding-top: 0;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        img {
            width: fit-content;
            transform: rotate(90deg);
            margin-top: 25px;
        }
    }

    .section-three-item__img {
        margin-right: 20px;
        img {
            max-height: 300px;
        }
    }
}
