.header {
    padding: 10px 0;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #000;
    z-index: 200;
    transition: all 0.3s ease-in-out;
    @media (min-width: 1200px) {
        &.fixed {
            .logo {
                width: 150px;
            }
            .lang {
                opacity: 0;
            }
        }
    }
}

.header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    transition: all 0.3s ease-in-out;
    width: 150px;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}

.nav {
}
.nav__list {
    display: flex;
    &:hover {
        .nav__item {
            opacity: 0.6;
        }
    }
}
.nav__item {
    margin-left: 25px;
    transition: all 0.3s ease-in-out;
    &:hover {
        opacity: 1 !important;
        .nav__link {
            &::before {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}
.nav__link {
    color: #fffefe;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        height: 1px;
        width: 100%;
        background-color: currentColor;
        bottom: -2px;
        transform: translateY(10px);
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
}
.lang {
    position: absolute;
    right: 15px;
    top: -4px;
    color: #fffefe;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    a {
        color: #fffefe;
        transition: all 0.3s ease-in-out;
        &:hover {
            opacity: 0.6;
        }
    }
}

@media (max-width: 1200px) {
    .nav {
        position: fixed;
        width: calc(100% - 60px);
        height: 100%;
        left: 0;
        top: 0;
        background-color: #000;
        z-index: 1222222;
        padding: 100px 20px;
        transform: translateX(-110%);
        transition: all 0.3s ease-in-out;
        &.active {
            transform: translateX(0);
        }
    }
    .nav__list {
        flex-direction: column;
    }
    .header {
        .burger {
            display: block;
        }
    }
    .logo {
        width: 100px;
        position: relative;
        z-index: 222222222;
    }
    .preloader img {
        width: 100px;
    }
    .nav__item {
        margin-bottom: 20px;
        margin-left: 0;
    }

    .lang {
        position: static;
        margin-top: 50px;
    }
}
