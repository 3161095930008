.lovers {
    padding: 85px 0;
    height: 670px;
    background-color: #3e3e3e;
}
.container {
}
.lovers__wrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
    min-height: 100%;
    z-index: 1;
}

.lovers__img {
    position: absolute;
    left: 120px;
    top: 0;
    max-width: 100%;
    height: 100%;
    max-height: 500px;
    object-fit: cover;
    z-index: -1;
}

.lovers__title {
    color: #ffffff;
    font-size: 70px;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 1.61px;
    max-width: 700px;
}
.lovers__right {
    max-width: 700px;
    margin-left: 40px;
    h3 {
        color: #ffffff;
        font-size: 28px;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 0.43px;
    }
    p {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.25px;
        margin-top: 75px;
    }
}
.lovers-icons {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0.25px;
        div {
            width: 90px;
            height: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media (max-width: 740px) {
    .lovers {
        padding: 40px 0;
        height: fit-content;
    }
    .lovers__wrapper {
        flex-direction: column;
        min-height: fit-content;
    }
    .lovers__img {
        opacity: 0.2;
    }
    .lovers__right {
        margin-left: 0;
        max-width: 100%;
        margin-top: 30px;
    }

    .lovers__title {
        font-size: 52px;
        font-weight: 700;
        line-height: 66px;
    }

    .lovers__right p {
        margin-top: 40px;
    }
}

@media (max-width: 480px) {
    .lovers-icons > div div {
        width: 50px;
        height: 50px;
    }
    .lovers-icons > div {
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
    }
}
